import _ from 'lodash';
import { getToken } from 'services/storages/userStorage';
import { Route, SubMenuHeader } from 'routes/routes.type';
import { PermissionMenuTypes } from 'helper/type/menu.type';
import { PermissionActionTypes } from 'helper/type/action.type';

const PAGES = {
  auth: '/auth',
  login: '/login',
  register: '/register',
  activationEmail: '/activation',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  branches: '/branches',

  home: '/',
  homeMobile: '/mobile',
  bookingRoom: '/',
  roomMap: '/room-map',
  bookingList: '/booking-list',
  cashier: '/cashier',
  group: '/new-group',
  listGroup: '/list-group',
  detailGroup: '/detail-group/:groupId',
  roomLock: '/room-lock',

  room: '/room',
  cleaningCalendar: '/cleaning-calendar',
  roomStatus: '/room-status',
  cleaningDetail: '/cleaning-detail',

  receptionist: '/receptionist',
  receptionistGuestWillCheckIn: '/receptionist/will-check-in',
  receptionistGuestCheckIn: '/receptionist/check-in',
  receptionistGuestAllot: '/receptionist/allot',
  receptionistGuestWillCheckOut: '/receptionist/will-checkout',
  receptionistGuestCheckout: '/receptionist/checkout',
  receptionistUnavailable: '/receptionist/unavailable',
  receptionistActualCheckIn: '/receptionist/actual-check-in',

  audit: '/audit',
  report: '/report',
  setting: '/setting',

  stayingGuests: '/staying-guests',
  nationalityStatistic: '/nationality-statistic',
  roomAvailability: '/room-availability',
  roomLockHistory: '/room-lock-history',
  transactions: '/transactions',
  transactionsV2: '/transactions-v2',
  breakfasts: '/breakfasts',
  houseStatus: '/house-status',
  shiftHandoverList: '/shift-handover-list',
  focsReport: '/focs-report',
  consolidateRevenueReport: '/consolidate-revenue-report',
  detailedAnalysisReport: '/detailed-analysis-report',

  customerProfile: '/customer-profile/:customerId',
  shiftHandover: '/shift-handover',
  scanCustomerInfo: '/customers-info-ocr',
  cashBookDetail: '/cash-book-detail',
  cashBookDetailV2: '/cash-book-detail-v2',
  nightAudit: '/night-audit',
  reservationForecast: '/reservation-forecast',
  warehouseManagement: '/warehouse-management',
  travelAgency: '/travel-agency',
  customersManagement: '/customers-management',
  addDiscount: '/add-discount/:bookingLine',

  hk: '/mobile/hk',
  subHK: '/mobile/sub-hk',

  chat: '/chat'
};

export function getFullUserProfilePath(userId: number) {
  return `${PAGES.customerProfile.replace(':customerId', String(userId))}`;
}

export function getAddDiscountPath(bookingLineId: number) {
  return `${PAGES.addDiscount.replace(':bookingLine', String(bookingLineId))}`;
}

export function getDetailGroupURL(groupId: number) {
  return `${PAGES.detailGroup.replace(':groupId', String(groupId))}`;
}

export function getRedirectUrl(redirect: string) {
  const token = getToken();
  return `${String(process.env.REACT_APP_WEB_URL)}/#${
    PAGES.auth
  }?token=${token}&redirect=${encodeURIComponent(redirect)}`;
}

export const PATTERN_URL = {
  HOME: '/',
  HK: '/mobile/hk/*',
  SUB_HK: '/mobile/sub-hk/*'
};

export const MENU_HEADER_VALUE = {
  BOOKING_ROOM: 1,
  RECEPTIONIST: 2,
  CASHIER: 3,
  GROUP: 4,
  ROOM: 5,
  REPORT: 6,
  CASHFLOW: 7,
  NIGHT_AUDIT: 8,
  MANAGE: 9,
  WAREHOUSE_MANAGEMENT: 10,
  TRAVEL_AGENCY: 11,
  CUSTOMERS_MANAGEMENT: 12,
  CHAT: 13,
  TRANSACTIONS: 14
};

export const MENU_HEADER: Route[] = [
  {
    id: MENU_HEADER_VALUE.BOOKING_ROOM,
    label: 'header.booking',
    url: PAGES.bookingRoom
  },
  {
    id: MENU_HEADER_VALUE.RECEPTIONIST,
    label: 'header.receptionist',
    url: PAGES.receptionistGuestWillCheckIn
  },
  {
    id: MENU_HEADER_VALUE.GROUP,
    label: 'header.group',
    url: PAGES.group
  },
  {
    id: MENU_HEADER_VALUE.ROOM,
    label: 'header.room',
    url: PAGES.cleaningCalendar
  },
  {
    id: MENU_HEADER_VALUE.CASHIER,
    key: MENU_HEADER_VALUE.CASHIER,
    label: 'header.cashier',
    url: PAGES.cashier
  },
  {
    id: MENU_HEADER_VALUE.MANAGE,
    label: 'header.manage',
    key: 'SubMenu',
    children: [
      {
        id: MENU_HEADER_VALUE.REPORT,
        key: MENU_HEADER_VALUE.REPORT,
        label: 'subHeader.manage.report',
        url: PAGES.stayingGuests
      },
      {
        id: MENU_HEADER_VALUE.TRANSACTIONS,
        key: MENU_HEADER_VALUE.TRANSACTIONS,
        label: 'subHeader.manage.transactions',
        url: PAGES.transactions,
        permission: PermissionMenuTypes.TRANSACTION_LIST
      },
      {
        id: MENU_HEADER_VALUE.NIGHT_AUDIT,
        key: MENU_HEADER_VALUE.NIGHT_AUDIT,
        label: 'subHeader.manage.nightAudit',
        url: PAGES.nightAudit,
        permission: PermissionMenuTypes.NIGHT_AUDIT
      },
      {
        id: MENU_HEADER_VALUE.CUSTOMERS_MANAGEMENT,
        key: MENU_HEADER_VALUE.CUSTOMERS_MANAGEMENT,
        label: 'subHeader.manage.customers',
        url: PAGES.customersManagement,
        permission: PermissionMenuTypes.CUSTOMER_LIST
      },
      {
        id: MENU_HEADER_VALUE.WAREHOUSE_MANAGEMENT,
        key: MENU_HEADER_VALUE.WAREHOUSE_MANAGEMENT,
        label: 'subHeader.manage.inventoryManagement',
        url: PAGES.warehouseManagement,
        permission: PermissionMenuTypes.INVENTORY_MANAGEMENT
      },
      {
        id: MENU_HEADER_VALUE.TRAVEL_AGENCY,
        key: MENU_HEADER_VALUE.TRAVEL_AGENCY,
        label: 'subHeader.manage.travelAgency',
        url: PAGES.travelAgency,
        permission: PermissionMenuTypes.TA_MANAGEMENT_VIEW
      },
      {
        id: MENU_HEADER_VALUE.CASHFLOW,
        key: MENU_HEADER_VALUE.CASHFLOW,
        label: 'subHeader.manage.cashBookDetail',
        url: PAGES.cashBookDetail,
        onlyV2: false
      },
      {
        id: MENU_HEADER_VALUE.CASHFLOW,
        key: MENU_HEADER_VALUE.CASHFLOW,
        label: 'subHeader.manage.cashBookDetail',
        url: PAGES.cashBookDetailV2,
        onlyV2: true
      }
    ]
  }
];

export const getMenuHeaderMobile = () => {
  const flattenMenu: any[] = _.flatMap(MENU_HEADER, item => (item.children ? item.children : item));
  return flattenMenu;
};

export const SUB_MENU_HEADER: SubMenuHeader = {
  [MENU_HEADER_VALUE.BOOKING_ROOM]: [
    {
      label: 'subHeader.booking.booking',
      url: PAGES.bookingRoom,
      permission: PermissionMenuTypes.BOOKING_ROOM
    },
    {
      label: 'subHeader.booking.roomMap',
      url: PAGES.roomMap,
      permission: PermissionMenuTypes.ROOM_MAP
    },
    {
      label: 'subHeader.booking.bookingList',
      url: PAGES.bookingList,
      permission: PermissionMenuTypes.BOOKING_LIST
    },
    {
      label: 'subHeader.booking.roomLock',
      url: PAGES.roomLock
    }
  ],
  [MENU_HEADER_VALUE.ROOM]: [
    {
      label: 'subHeader.room.cleaningCalendar',
      url: PAGES.cleaningCalendar,
      permission: PermissionMenuTypes.CLEANING_CALENDER
    },
    {
      label: 'subHeader.room.roomStatus',
      url: PAGES.roomStatus,
      permission: PermissionMenuTypes.ROOM_STATUS
    }
  ],
  [MENU_HEADER_VALUE.RECEPTIONIST]: [
    {
      label: 'subHeader.receptionist.guestsWillCome',
      url: PAGES.receptionistGuestWillCheckIn,
      permission: PermissionMenuTypes.WILL_CHECK_IN
    },
    {
      label: 'subHeader.receptionist.guestsHaveArrived',
      url: PAGES.receptionistGuestCheckIn,
      permission: PermissionMenuTypes.ARRIVAL_GUEST
    },
    {
      label: 'subHeader.receptionist.guestsAreStaying',
      url: PAGES.receptionistGuestAllot,
      permission: PermissionMenuTypes.IN_HOUSE_GUEST
    },
    {
      label: 'subHeader.receptionist.guestWillCheckOut',
      url: PAGES.receptionistGuestWillCheckOut,
      permission: PermissionMenuTypes.WILL_CHECK_OUT
    },
    {
      label: 'subHeader.receptionist.guestCheckedOut',
      url: PAGES.receptionistGuestCheckout,
      permission: PermissionMenuTypes.CHECK_OUT_ROOM_LIST
    },
    {
      label: 'subHeader.receptionist.roomOccupancy',
      url: PAGES.receptionistUnavailable,
      permission: PermissionMenuTypes.UNAVAILABLE
    },
    {
      label: 'subHeader.receptionist.actualArrivalDate',
      url: PAGES.receptionistActualCheckIn,
      permission: PermissionMenuTypes.ACTUAL_CHECK_IN
    }
  ],
  [MENU_HEADER_VALUE.GROUP]: [
    {
      label: 'subHeader.group.createGroup',
      url: PAGES.group,
      permission: PermissionActionTypes.CREATE_NEW_GROUP
    },
    {
      label: 'subHeader.group.groupList',
      url: PAGES.listGroup,
      permission: PermissionMenuTypes.GROUP_LIST
    }
  ],
  [MENU_HEADER_VALUE.REPORT]: [
    {
      label: 'manageReportSubHeader.caDeclaration',
      url: PAGES.stayingGuests,
      permission: PermissionMenuTypes.CA_DECLARATION
    },
    {
      label: 'manageReportSubHeader.roomAvailability',
      url: PAGES.roomAvailability,
      permission: PermissionMenuTypes.ROOM_AVAILABILITY
    },
    {
      label: 'manageReportSubHeader.roomLockHistoryReport',
      url: PAGES.roomLockHistory
    },
    {
      label: 'manageReportSubHeader.reservationForecast',
      url: PAGES.reservationForecast,
      permission: PermissionMenuTypes.RESERVATION_FORECAST
    },
    {
      label: 'manageReportSubHeader.breakfasts',
      url: PAGES.breakfasts,
      permission: PermissionMenuTypes.BREAKFAST
    },
    {
      label: 'manageReportSubHeader.houseStatus',
      url: PAGES.houseStatus,
      permission: PermissionMenuTypes.HOUSE_STATUS
    },
    {
      label: 'manageReportSubHeader.nationalityStatistic',
      url: PAGES.nationalityStatistic
    },
    {
      label: 'manageReportSubHeader.shiftHandoverList',
      url: PAGES.shiftHandoverList,
      permission: PermissionMenuTypes.SHIFT_HANDOVER_LIST
    },
    {
      label: 'manageReportSubHeader.focsReport',
      url: PAGES.focsReport
    },
    {
      label: 'manageReportSubHeader.consolidateRevenueReport',
      url: PAGES.consolidateRevenueReport,
      permission: PermissionMenuTypes.CONSOLIDATE_REVENUE_REPORT
    },
    {
      label: 'manageReportSubHeader.detailedAnalysisReport',
      url: PAGES.detailedAnalysisReport,
      permission: PermissionMenuTypes.DETAILED_ANALYSIS_REPORT
    }
  ],
  [MENU_HEADER_VALUE.WAREHOUSE_MANAGEMENT]: [
    {
      label: 'manageInventoryManagementSubHeader.warehouseManagement',
      url: PAGES.warehouseManagement,
      permission: PermissionMenuTypes.INVENTORY_MANAGEMENT
    }
  ],
  [MENU_HEADER_VALUE.CASHIER]: [
    {
      label: 'Giao dịch',
      url: PAGES.cashier
    }
  ],
  [MENU_HEADER_VALUE.TRANSACTIONS]: [
    {
      label: 'manageTransactionsSubHeader.transactions',
      url: PAGES.transactions,
      permission: PermissionMenuTypes.TRANSACTION_LIST
    },
    {
      label: 'manageTransactionsSubHeader.transactionsV2',
      url: PAGES.transactionsV2,
      permission: PermissionMenuTypes.TRANSACTION_LIST
    }
  ]
};

export default PAGES;
