import React, { useMemo } from 'react';
import { Link, useLocation, matchPath } from 'react-router-dom';

import useCommonStore from 'stores/useCommon';
import PAGES from 'routes/constants';
import 'styles/common.scss';
import { usePermissions } from 'AuthorizePermission';
import { getFilteredSubMenuHeaderAndMenuHeader } from 'helper/common/permission.menu.helper';

import { useTranslation } from 'react-i18next';

export const WITHOUT_NAVIGATION_BAR_URL = [
  PAGES.nightAudit,
  PAGES.travelAgency,
  PAGES.detailGroup,
  PAGES.cashier
];

function NavigateBar() {
  const { t } = useTranslation();

  const location = useLocation();
  const { activeMenuId, activeMenuManageId, currentBranch } = useCommonStore();
  const { permissions, hasPermission } = usePermissions();

  const withoutNavigateBar = useMemo(() => {
    if (WITHOUT_NAVIGATION_BAR_URL.some(item => matchPath(item, location.pathname))) {
      return true;
    }
    return false;
  }, [location.pathname]);

  const filteredSubMenuHeader = useMemo(() => {
    return getFilteredSubMenuHeaderAndMenuHeader(permissions, hasPermission).filteredSubMenuHeader;
  }, [permissions, hasPermission]);

  const subMenuHeader = useMemo(() => {
    return filteredSubMenuHeader[activeMenuId] || filteredSubMenuHeader[activeMenuManageId];
  }, [activeMenuId, activeMenuManageId, filteredSubMenuHeader]);

  if ((!activeMenuId && !activeMenuManageId) || withoutNavigateBar) {
    return null;
  }

  return (
    <div className="pms-navigate-bar">
      <ul className="pms-page-menu">
        {subMenuHeader &&
          subMenuHeader
            .filter(item =>
              currentBranch?.is_enable_v2
                ? item.url !== PAGES.cashBookDetail
                : item.url !== PAGES.cashBookDetailV2
            )
            .map((item, index) => (
              <li key={index} className={matchPath(item.url, location.pathname) ? 'active' : ''}>
                <Link to={item.url}>{t(item.label)}</Link>
              </li>
            ))}
      </ul>
    </div>
  );
}

export default NavigateBar;
