import React, { useEffect, useState } from 'react';
import { Modal, Button, notification, Input, Form, Row, Col } from 'antd';
import useModal from 'stores/useModal';
import { useMutation } from '@tanstack/react-query';
import API from 'services/api';
import { useGetBookingConfirmationTemplate } from 'hooks/useGetBookingConfirmationTemplate';
import 'styles/modal-booking-confirmation.scss';
import { BookingConfirmationTemplate } from 'services/api/type/booking.type';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { EmailRegex } from 'constants/regex';
import { MESSAGE_CODE } from 'constants/validate';

const BookingConfirmation: React.FC = () => {
  const [form] = Form.useForm();
  const {
    isOpenBookingConfirmation,
    setIsOpenBookingConfirmation,
    setInfoConfirmModal,
    setConfirmLoading,
    bookingId
  } = useModal();

  const [updatedHtmlContent, setUpdatedHtmlContent] = useState<string>('');
  const [localTemplate, setLocalTemplate] = useState<BookingConfirmationTemplate[]>([]);

  const { data: bookingConfirmationTemplate } = useGetBookingConfirmationTemplate(
    Number(bookingId)
  );

  useEffect(() => {
    if (bookingConfirmationTemplate?.length && isOpenBookingConfirmation) {
      setUpdatedHtmlContent(bookingConfirmationTemplate.at(0)?.body_html || '');
      setLocalTemplate(bookingConfirmationTemplate);
      form.setFieldsValue({
        email_to: bookingConfirmationTemplate.at(0)?.email_to,
        subject: bookingConfirmationTemplate.at(0)?.subject
      });
    }
    // eslint-disable-next-line
  }, [bookingConfirmationTemplate, isOpenBookingConfirmation]);

  useEffect(() => {
    if (!isOpenBookingConfirmation) {
      form.resetFields();
    }
    // eslint-disable-next-line
  }, [isOpenBookingConfirmation]);

  const { mutateAsync: mutateSendBookingConfirmation } = useMutation({
    mutationFn: (body: BookingConfirmationTemplate) => API.booking.sendBookingConfirmation(body)
  });

  const handleCancel = () => {
    setIsOpenBookingConfirmation(false, undefined);
  };

  const handleContentChange = () => {
    const modalContent = document.getElementById('modal-content');
    if (modalContent) {
      const newHtmlContent = modalContent.innerHTML;
      setUpdatedHtmlContent(newHtmlContent);

      if (localTemplate.length > 0) {
        const updatedTemplate = [...localTemplate];
        updatedTemplate[0] = {
          ...updatedTemplate[0],
          body_html: newHtmlContent
        };
        setLocalTemplate(updatedTemplate);
      }
    }
  };

  useEffect(() => {
    const modalContent = document.getElementById('modal-content');
    if (modalContent) {
      modalContent.addEventListener('blur', handleContentChange, true);
    }
    return () => {
      const modalContent = document.getElementById('modal-content');
      if (modalContent) {
        modalContent.removeEventListener('blur', handleContentChange, true);
      }
    };
    // eslint-disable-next-line
  }, [localTemplate]);

  const handleConvertToPdf = () => {
    if (updatedHtmlContent) {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = updatedHtmlContent;
      tempDiv.style.position = 'absolute';
      tempDiv.style.left = '-9999px';
      tempDiv.style.width = 'auto';
      tempDiv.style.maxWidth = '800px';
      document.body.appendChild(tempDiv);

      html2canvas(tempDiv, {
        scale: 2,
        useCORS: true,
        backgroundColor: null
      })
        .then(canvas => {
          const imgData = canvas.toDataURL('image/png');
          const pdfWidth = 210;
          const imgWidth = pdfWidth;

          const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

          const pdf = new jsPDF('p', 'mm', [pdfWidth, pdfHeight]);

          pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, pdfHeight);
          pdf.save('booking_confirmation.pdf');

          document.body.removeChild(tempDiv);
        })
        .catch(err => {
          console.error('Error generating PDF:', err);
          document.body.removeChild(tempDiv);
        });
    }
  };

  const confirmSendEmail = () => {
    setInfoConfirmModal(true, {
      title: 'Xác nhận gửi mail',
      okText: 'Gửi',
      onOk: async () => {
        if (localTemplate.length > 0) {
          try {
            setConfirmLoading(true);
            await mutateSendBookingConfirmation(localTemplate[0]);
            setInfoConfirmModal(false);
            notification.success({
              message: 'Sent mail successfully'
            });
          } catch (err: any) {
            notification.error({
              message: 'Failed to send mail'
            });
          } finally {
            setConfirmLoading(false);
          }
        }
      }
    });
  };

  const handleRefreshContents = () => {
    if (bookingConfirmationTemplate?.length) {
      setUpdatedHtmlContent(bookingConfirmationTemplate[0]?.body_html || '');
      setLocalTemplate(bookingConfirmationTemplate);
      form.setFieldsValue({
        email_to: bookingConfirmationTemplate[0]?.email_to,
        subject: bookingConfirmationTemplate[0]?.subject
      });
      notification.success({
        message: 'Refreshed successfully'
      });
    } else {
      notification.error({
        message: 'No template found to refresh'
      });
    }
  };

  return (
    <Modal
      title="BOOKING CONFIRMATION"
      className="pms-modal-booking-confirmation"
      centered
      width={1200}
      open={isOpenBookingConfirmation}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose
    >
      <Form form={form}>
        <div className="pms-modal-booking-confirmation__container">
          <div
            id="modal-content"
            contentEditable="true"
            className="pms-modal-booking-confirmation__content"
            dangerouslySetInnerHTML={{ __html: updatedHtmlContent }}
          />
          <div className="pms-modal-booking-confirmation__button_and_template_info">
            <div className="pms-modal-booking-confirmation__template_info">
              <Row gutter={[8, 8]}>
                <Col span={5} className="label">
                  Email:{' '}
                </Col>
                <Col span={19}>
                  <Form.Item
                    name="email_to"
                    rules={[
                      {
                        pattern: EmailRegex,
                        message: MESSAGE_CODE.WRONG_FORMAT_EMAIL
                      }
                    ]}
                  >
                    <Input
                      onChange={e => {
                        const newEmail = e.target.value;
                        if (localTemplate.length > 0) {
                          const updatedTemplate = [...localTemplate];
                          updatedTemplate[0] = {
                            ...updatedTemplate[0],
                            email_to: newEmail
                          };
                          setLocalTemplate(updatedTemplate);
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={5} className="label">
                  Subject:{' '}
                </Col>
                <Col span={19}>
                  <Form.Item name="subject">
                    <Input
                      onChange={e => {
                        const newSubject = e.target.value;
                        if (localTemplate.length > 0) {
                          const updatedTemplate = [...localTemplate];
                          updatedTemplate[0] = {
                            ...updatedTemplate[0],
                            subject: newSubject
                          };
                          setLocalTemplate(updatedTemplate);
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <Button type="primary" className="ant-btn-secondary" onClick={confirmSendEmail}>
              Send
            </Button>
            <Button
              type="primary"
              className="ant-btn-secondary"
              onClick={() => handleRefreshContents()}
            >
              Reset
            </Button>

            <Button type="primary" className="ant-btn-secondary" onClick={handleConvertToPdf}>
              Save to PDF
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default BookingConfirmation;
