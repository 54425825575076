import { getColumnRoomsFocTab } from 'constants/table';
import CustomTable from '../room-available-components/CustomTable';
import { useGetRoomsFoc } from 'hooks/useGetFocs';
import { GetFocsType } from 'services/api/type/report.type';
import { useEffect, useMemo } from 'react';

interface RoomsFocProps {
  isEnable: boolean;
  filterOptions: GetFocsType;
  t: (key: string) => string;
  dataSource: (record: any[]) => void;
}

export default function RoomsFocTab({ isEnable, filterOptions, t, dataSource }: RoomsFocProps) {
  const { data: roomsFoc = [], isLoading } = useGetRoomsFoc(filterOptions, isEnable);

  const dataWithTotal = useMemo(() => {
    if (!roomsFoc.length) return [];

    const totalRoomQty = roomsFoc.reduce((acc: number, room: any) => acc + (room.room_qty || 0), 0);
    const totalRoomPrice = roomsFoc.reduce(
      (acc: number, room: any) => acc + (room.room_price || 0),
      0
    );

    return [
      ...roomsFoc,
      {
        booking_id: t('managePage.focsReportPage.common.total'),
        room_qty: totalRoomQty,
        partner_name: '',
        room_type: '',
        date_of_foc: '',
        room_price: totalRoomPrice,
        check_in: null,
        check_out: null
      }
    ];
  }, [roomsFoc, t]);

  useEffect(() => {
    if (dataWithTotal.length) {
      dataSource(dataWithTotal);
    }
  }, [dataWithTotal, dataSource]);

  const columnRoomsFocTab = useMemo(() => {
    return getColumnRoomsFocTab(t);
  }, [t]);

  return (
    <CustomTable
      rowKey="id"
      loading={isLoading}
      columns={columnRoomsFocTab}
      dataSource={dataWithTotal}
      t={t}
    />
  );
}
