import { useQuery } from '@tanstack/react-query';
import QUERY_KEYS from 'services/api/queryKeys';
import API from 'services/api';

export const useGetBookingConfirmationTemplate = (bookingId: number) => {
  const { data, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.GET_SERVICES],
    queryFn: () => API.booking.getBookingConfirmationTemplate(Number(bookingId)),
    refetchOnWindowFocus: false,
    enabled: !!bookingId
  });
  return {
    data,
    isFetching
  };
};
