import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import classNames from 'classnames';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  DatePickerProps,
  Form,
  FormProps,
  InputNumber,
  notification,
  Row,
  Select,
  Table
} from 'antd';
import { UserOutlined, CloseCircleFilled } from '@ant-design/icons';

import CustomerInfo from 'components/booking-room/CustomerInfo';
import useBookingStore from 'stores/useBooking';
import useModal from 'stores/useModal';
import { useGetAvailRoomType } from 'hooks/useGetAvailRoomType';

import { getFoundColumns } from 'constants/table';
import { CURRENCY, PAYMENT_METHOD, RENT_METHOD } from 'constants/form';
import QUERY_KEYS from 'services/api/queryKeys';
import { RangeValueType } from 'services/api/type/common.type';
import { Pricing, RoomType } from 'services/api/type/room.type';
import { getDateTodayMin2PM, getNextDateAt, toUtcTime } from 'utils';

import { ReactComponent as IconBaby } from 'assets/images/baby.svg';
import { actionCreateBooking } from 'services/api/module/booking.api';
import { addCustomer } from 'services/api/module/customer.api';
import 'styles/booking-room.scss';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useOpenBookingDetail } from 'hooks/useOpenBookingDetail';

import { useTranslation } from 'react-i18next';

const disabledDate: DatePickerProps['disabledDate'] = (current, { from }) => {
  if (from) {
    return current.isBefore(from);
  }

  return false;
};

function BookingRoom() {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const roomTypesForm: RoomType[] = Form.useWatch('roomTypes', form);
  // const foc: boolean = Form.useWatch('foc', form);

  const { setInfoConfirmModal } = useModal();
  const { openBookingDetail } = useOpenBookingDetail();
  const { setBookingLineId } = useBookingStore();
  const [rangeDate, setRangeDate] = useState<RangeValueType>([
    getDateTodayMin2PM(),
    getNextDateAt(1, 12)
  ]);

  const [totalAvailableRoom, setTotalAvailableRoom] = useState(0);
  const [night, setNight] = useState(0);
  const [isShowCustomerInfo, setIsShowCustomerInfo] = useState(false);

  const { data: availRoomTypes, isFetching } = useGetAvailRoomType(
    toUtcTime(rangeDate[0], 'YYYY-MM-DD HH:mm:ss'),
    toUtcTime(rangeDate[1], 'YYYY-MM-DD HH:mm:ss')
  );
  const [roomTypes, setRoomTypes] = useState<RoomType[]>([]);

  const { mutateAsync: mutateCheckCreateBooking, isPending: isPendingCreateBooking } = useMutation({
    mutationFn: async (values: any) => {
      let partner_id = values.customer.profile_id;
      if (!partner_id) {
        const customer = await addCustomer({
          ...values.customer,
          dob: values.customer.dob?.format('YYYY-MM-DD')
        });
        partner_id = customer.id;
      }
      return actionCreateBooking({
        partner_id,
        source_id: values.source_id,
        medium_id: values.medium_id,
        hotel_travel_agency_id: values.hotel_travel_agency_id,
        check_in: toUtcTime(rangeDate[0], 'YYYY-MM-DD HH:mm:ss'),
        check_out: toUtcTime(rangeDate[1], 'YYYY-MM-DD HH:mm:ss'),
        note: values.note,
        booking_lines: values.roomTypes.map((item: any) => {
          const result: any = {
            pricelist_id: item.pricelist_id,
            room_id: item.room_id,
            room_type_id: item.room_type_id,
            quantity: item.used_rooms,
            adult: item.adult || 0,
            child: item.child || 0,
            has_breakfast: item.has_breakfast || false,
            is_foc: item.is_foc,
            reason_approve_by: item.reason_approve_by,
            level: item.level
          };

          if (item.is_editable_price) {
            result.price = item.price;
          }

          if (item.is_foc) {
            result.price = 0;
          }

          return result;
        })
      });
    }
  });

  useEffect(() => {
    calculateNight([rangeDate[0], rangeDate[1]]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const total = (availRoomTypes || []).reduce((cur, next) => {
      return cur + (next.available_rooms - (next.used_rooms || 0));
    }, 0);

    setRoomTypes(availRoomTypes);
    setTotalAvailableRoom(total);
  }, [availRoomTypes]);

  const handleRangeDateChange = (value: RangeValueType | null) => {
    if (value) {
      setRangeDate(value);
      setTimeout(() => {
        calculateNight(value);
      }, 500);
      form.resetFields();
    }
  };

  const calculateNight = (value: RangeValueType | null) => {
    let startDate: Dayjs | null | undefined = _.get(value, '0');
    let endDate: Dayjs | null | undefined = _.get(value, '1');
    if (startDate) {
      startDate = startDate.set('hour', 0).set('minute', 0).set('second', 0);
    }
    if (endDate) {
      endDate = endDate.set('hour', 23).set('minute', 59).set('second', 59);
      setNight(endDate.diff(startDate, 'd'));
    }
  };

  const handleChangeNumberRoom = (value: number, roomTypeId: number) => {
    const cloneRoomTypes = _.cloneDeep(roomTypes);
    const isFoc = form.getFieldValue('foc');
    const roomType = _.find(cloneRoomTypes, { room_type_id: roomTypeId });
    if (roomType) {
      _.set(roomType, 'used_rooms', value);

      // Modify form value
      const cloneRoomTypesForm = _.cloneDeep(roomTypesForm);
      const roomTypeFormItem = _.find(cloneRoomTypesForm, {
        room_type_id: roomTypeId
      });

      if (!value) {
        _.remove(cloneRoomTypesForm, { room_type_id: roomTypeId });
      } else {
        if (roomTypeFormItem) {
          _.set(roomTypeFormItem, 'used_rooms', value);
          _.set(roomTypeFormItem, 'adult', roomType.max_adult * value);
          _.set(roomTypeFormItem, 'child', roomType.max_child * value);
        } else {
          const cloneRoom = _.cloneDeep(roomType);
          _.set(cloneRoom, 'adult', roomType.max_adult);
          _.set(cloneRoom, 'child', roomType.max_child);
          _.set(cloneRoom, 'is_foc', isFoc);
          if (roomType.edited_price) {
            _.set(cloneRoom, 'price', roomType.edited_price);
          }
          cloneRoomTypesForm.push(cloneRoom);
        }
      }

      form.setFieldValue('roomTypes', cloneRoomTypesForm);
    }

    const total = (cloneRoomTypes || []).reduce((cur, next) => {
      return cur + (next.available_rooms - (next.used_rooms || 0));
    }, 0);

    setTotalAvailableRoom(total);
    setRoomTypes(cloneRoomTypes);
  };

  const handleChangePriceType = (value: string | number, roomTypeId: number) => {
    handleChangeValueBookingField('pricelist_id', value, roomTypeId);
  };

  const handleChangeEditPrice = (e: CheckboxChangeEvent, roomTypeId: number) => {
    handleChangeValueBookingField('is_editable_price', e.target.checked, roomTypeId);
  };

  const handleChangePrice = (newPrice: number | null, roomTypeId: number) => {
    handleChangeValueBookingField('edited_price', newPrice, roomTypeId);
  };

  const handleChangeValueBookingField = (field: string, value: any, roomTypeId: number) => {
    const cloneRoomTypes = _.cloneDeep(roomTypes);
    const roomType = _.find(cloneRoomTypes, { room_type_id: roomTypeId });
    if (roomType) {
      _.set(roomType, field, value);

      const cloneRoomTypesForm = _.cloneDeep(roomTypesForm);
      const roomTypeFormItem = _.find(cloneRoomTypesForm, {
        room_type_id: roomTypeId
      });

      if (roomTypeFormItem) {
        _.set(roomTypeFormItem, field, value);
      }

      // Override price from price list
      if (field === 'pricelist_id') {
        const pricing = _.get(roomType, 'pricing', []);
        const renderPrice: number | undefined =
          pricing.find(item => item.pricelist_id === value)?.price || _.get(roomType, 'price', 0);
        _.set(roomType, 'edited_price', renderPrice);

        if (roomTypeFormItem) {
          _.set(roomTypeFormItem, 'price', renderPrice);
        }
      } else if (field === 'edited_price' && roomTypeFormItem) {
        _.set(roomTypeFormItem, 'price', value);
      } else if (field === 'is_editable_price') {
        if (value) {
          const editedPrice = _.get(roomType, 'edited_price');
          if (!_.isNil(editedPrice) && roomTypeFormItem) {
            _.set(roomTypeFormItem, 'price', editedPrice);
          }
        } else {
          const pricing = _.get(roomType, 'pricing', []);
          const pricelist_id = _.get(roomType, 'pricelist_id');
          const renderPrice: number | undefined =
            pricing.find(item => item.pricelist_id === pricelist_id)?.price ||
            _.get(roomType, 'price', 0);

          if (roomTypeFormItem) {
            _.set(roomTypeFormItem, 'price', renderPrice);
          }
        }
      }

      form.setFieldValue('roomTypes', cloneRoomTypesForm);
    }

    setRoomTypes(cloneRoomTypes);
  };

  const handleContinue = () => {
    // form.submit();
    setIsShowCustomerInfo(true);
  };

  const handleBack = () => {
    setIsShowCustomerInfo(false);
  };

  const handleRemoveItemBooking = (remove: Function, fieldIndex: number, roomTypeId: number) => {
    const cloneRoomTypes = _.cloneDeep(roomTypes);
    const roomType = _.find(cloneRoomTypes, { room_type_id: roomTypeId });

    if (roomType) {
      _.set(roomType, 'used_rooms', 0);
    }
    const total = (cloneRoomTypes || []).reduce((cur, next) => {
      return cur + (next.available_rooms - (next.used_rooms || 0));
    }, 0);

    remove(fieldIndex);
    setTotalAvailableRoom(total);
    setRoomTypes(cloneRoomTypes);
  };

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    try {
      const data = await mutateCheckCreateBooking(values);
      notification.success({
        message: 'Tạo đơn đặt phòng thành công'
      });
      openBookingDetail(data.booking_lines[0].booking_line_id);
      setBookingLineId(data.booking_lines[0].booking_line_id);
      setIsShowCustomerInfo(false);
      await queryClient.resetQueries({
        queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_AVAIL_ROOM_TYPE]
      });
      form.resetFields();
    } catch (err: any) {
      console.log('err', err);
      notification.error({
        message: err.error || 'Có lỗi xảy ra'
      });
    }
  };

  const foundColumnList = useMemo(() => {
    return getFoundColumns(
      totalAvailableRoom,
      handleChangeNumberRoom,
      handleChangePriceType,
      handleChangeEditPrice,
      handleChangePrice,
      t
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomTypes, roomTypesForm, totalAvailableRoom, t]);

  const getRangeDateDisplay = () => {
    const startDate: Dayjs | null = rangeDate[0];
    const endDate: Dayjs | null = rangeDate[1];
    if (startDate && endDate) {
      return `${startDate.format('DD/MM/YYYY HH:mm')} - ${endDate.format('DD/MM/YYYY HH:mm')}`;
    }
    return '';
  };

  const handleShowConfirmCreateBooking = () => {
    const customerName = form.getFieldValue('partner_id');
    if (!customerName) {
      form.submit();
      return;
    }

    setInfoConfirmModal(true, {
      title: 'Xác nhận đặt phòng',
      okText: 'Đặt phòng',
      onOk: () => {
        setInfoConfirmModal(false);
        form.submit();
      }
    });
  };

  const totalRoom = useMemo(() => {
    if (roomTypesForm) {
      return roomTypesForm.reduce((cur, next) => {
        return cur + (next.used_rooms || 0);
      }, 0);
    }
    return 0;
  }, [roomTypesForm]);

  const totalAmount = useMemo(() => {
    let total = 0;

    if (roomTypesForm && roomTypesForm.length > 0) {
      for (let i = 0; i < roomTypesForm.length; i++) {
        const next = roomTypesForm[i];
        let calculatePrice = next.price;
        const pricing = next.pricing;
        const priceListId = next.pricelist_id;

        if (priceListId && !next.is_editable_price) {
          calculatePrice = (
            _.find(pricing, {
              pricelist_id: priceListId
            }) as Pricing
          )?.price;
        } else if (next.is_editable_price) {
          calculatePrice = next.price;
        } else if (next.is_foc) {
          calculatePrice = 0;
        }

        total += Number(next.used_rooms) * calculatePrice;
      }
      return total * night;
    }
    return 0;
  }, [night, roomTypesForm]);

  const totalAdult = useMemo(() => {
    if (roomTypesForm) {
      return roomTypesForm.reduce((cur, next) => {
        return cur + (next.adult || 0);
      }, 0);
    }
    return 0;
  }, [roomTypesForm]);

  const totalChild = useMemo(() => {
    if (roomTypesForm) {
      return roomTypesForm.reduce((cur, next) => {
        return cur + (next.child || 0);
      }, 0);
    }
    return 0;
  }, [roomTypesForm]);

  const renderLeftSide = () => {
    const hideBookingTable = { display: isShowCustomerInfo ? 'none' : 'block' };
    return (
      <>
        <CustomerInfo form={form} isHidden={!isShowCustomerInfo} t={t} />
        <div className="pms-booking-room__filter-container" style={hideBookingTable}>
          <p className="label-mobile">Chọn ngày/giờ</p>
          <div className="pms-booking-room__filter">
            <div className="pms-booking-room__filter-range-date">
              <p className="label">{t('bookingPage.booking.selectDateTime')}</p>
              <DatePicker.RangePicker
                value={rangeDate}
                placeholder={[
                  t('bookingPage.booking.beginningTime'),
                  t('bookingPage.booking.endingTime')
                ]}
                disabledDate={disabledDate}
                minDate={dayjs()}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [dayjs('14:00:00', 'HH:mm:ss'), dayjs('12:00:00', 'HH:mm:ss')]
                }}
                format="YYYY-MM-DD HH:mm"
                onChange={handleRangeDateChange}
              />
            </div>
          </div>
        </div>

        <div className="pms-booking-room__table" style={hideBookingTable}>
          <Table
            loading={isFetching}
            rowKey="room_type_id"
            columns={foundColumnList}
            dataSource={roomTypes}
            pagination={false}
            scroll={{ x: 1000 }}
            locale={{
              emptyText: <span className="empty-data">Không có dữ liệu</span>
            }}
          />
        </div>
        <p className="pms-found-room-note m-0" style={hideBookingTable}>
          <span className="required">*</span> {t('bookingPage.booking.foundRoomNote')}
        </p>
      </>
    );
  };

  return (
    <div className="pms-booking-room">
      <Form
        form={form}
        name="booking-form"
        layout="vertical"
        style={{ width: '100%' }}
        initialValues={{
          roomTypes: [],
          currency: CURRENCY.VND,
          paymentMethod: PAYMENT_METHOD.CASH,
          money: 0,
          check_in: rangeDate[0],
          check_out: rangeDate[1],
          foc: false,
          level: '',

          //  customer form
          adult: 0,
          numberChildren: 0,
          rentMethod: RENT_METHOD.LONG_TERM,
          personalInfos: []
        }}
        onFinish={onFinish}
        colon={false}
        autoComplete="off"
      >
        <Row gutter={[16, 16]}>
          <Col span={24} lg={16} xxl={17}>
            {renderLeftSide()}
          </Col>

          <Col
            span={24}
            lg={8}
            xxl={7}
            style={{ display: !_.isEmpty(roomTypesForm) ? 'block' : 'none' }}
          >
            <Button.Group className="w-full" style={{ gap: '8px' }}>
              {isShowCustomerInfo && (
                <Button type="primary" className="w-full" size="large" onClick={handleBack}>
                  Quay lại
                </Button>
              )}
              {!isShowCustomerInfo && (
                <Button className="ant-btn-secondary w-full" size="large" onClick={handleContinue}>
                  Tiếp tục
                </Button>
              )}
              {isShowCustomerInfo && (
                <Button
                  className="ant-btn-secondary w-full"
                  size="large"
                  loading={isPendingCreateBooking}
                  onClick={handleShowConfirmCreateBooking}
                >
                  Đặt phòng
                </Button>
              )}
            </Button.Group>
            <div className="pms-summary">
              <div className="pms-summary__title-wrapper">
                <span className="pms-summary__title">{getRangeDateDisplay()}</span>
                <span className="pms-summary__night">{night} đêm</span>
              </div>

              <div className="pms-summary__form">
                <Form.List name="roomTypes">
                  {(fields, { remove }) => {
                    return (
                      <div className="pms-summary__form-item w-full">
                        {fields.map(field => {
                          const currentRoomType: RoomType = form.getFieldValue([
                            'roomTypes',
                            field.name
                          ]);
                          const listRoom = currentRoomType.rooms;
                          const roomTypeName = currentRoomType.room_type_name;
                          let renderPrice = currentRoomType.price;
                          const pricing = currentRoomType.pricing;
                          const priceListId = currentRoomType.pricelist_id;
                          const usedRooms = currentRoomType.used_rooms || 0;

                          if (priceListId && !currentRoomType.is_editable_price) {
                            renderPrice = (
                              _.find(pricing, {
                                pricelist_id: priceListId
                              }) as Pricing
                            )?.price;
                          }

                          if (currentRoomType.is_foc) {
                            renderPrice = 0;
                          }

                          return (
                            <div
                              key={field.key}
                              className={classNames('pms-summary__form-room-type-wrapper', {
                                'allowed-remove': fields.length > 1
                              })}
                            >
                              <div className="pms-summary__form-room-type">
                                <Row gutter={[16, 16]}>
                                  <Col span={12}>
                                    {usedRooms < 2 ? (
                                      <Form.Item
                                        label={roomTypeName}
                                        className="horizontal-form-item"
                                        name={[field.name, 'room_id']} //  change field here if needed
                                      >
                                        <Select
                                          placeholder="Chọn phòng"
                                          options={listRoom
                                            .filter(item => item.room_status === 'available')
                                            .map(item => ({
                                              value: item.room_id,
                                              label: item.attributes.room_no
                                            }))}
                                        />
                                      </Form.Item>
                                    ) : (
                                      <p className="m-0">{roomTypeName}</p>
                                    )}
                                  </Col>
                                  <Col
                                    span={6}
                                    className="flex items-center pms-summary__form-room-price"
                                  >
                                    <span>
                                      {renderPrice?.toLocaleString('vn')}{' '}
                                      {usedRooms > 1 ? `x${usedRooms}` : ''}
                                    </span>
                                  </Col>
                                  <Col
                                    span={6}
                                    className="flex items-center pms-summary__form-room-price-total"
                                  >
                                    <span>
                                      {((renderPrice || 0) * usedRooms)?.toLocaleString('vn')}{' '}
                                    </span>
                                  </Col>
                                </Row>
                              </div>

                              <div className="pms-summary__form-room-guest">
                                <Row gutter={[16, 16]}>
                                  <Col
                                    span={12}
                                    className="flex items-center"
                                    style={{ gap: '12px' }}
                                  >
                                    <Form.Item
                                      // label="Người lớn"
                                      name={[field.name, 'adult']}
                                    >
                                      <InputNumber
                                        min={0}
                                        prefix={
                                          <UserOutlined
                                            style={{
                                              color: 'rgba(0, 0, 0, 0.3)'
                                            }}
                                          />
                                        }
                                        // max={maxAdult * usedRooms}
                                        style={{ width: '80px' }}
                                      />
                                    </Form.Item>

                                    <Form.Item
                                      // label="Trẻ em"
                                      name={[field.name, 'child']}
                                    >
                                      <InputNumber
                                        min={0}
                                        prefix={<IconBaby />}
                                        // max={maxChild * usedRooms}
                                        style={{ width: '80px' }}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <div className="breakfast-row">
                                  <Form.Item
                                    name={[field.name, 'has_breakfast']}
                                    valuePropName="checked"
                                  >
                                    <Checkbox>
                                      <span className="text-breakfast">
                                        {t('common.bookingData.hasBreakfast')}
                                      </span>
                                    </Checkbox>
                                  </Form.Item>
                                </div>
                              </div>

                              {fields.length > 1 && (
                                <Button
                                  className="btn-remove"
                                  icon={
                                    <CloseCircleFilled
                                      style={{
                                        fontSize: '20px',
                                        color: '#d9390d'
                                      }}
                                    />
                                  }
                                  type="text"
                                  onClick={() =>
                                    handleRemoveItemBooking(
                                      remove,
                                      field.name,
                                      currentRoomType.room_type_id
                                    )
                                  }
                                />
                              )}
                            </div>
                          );
                        })}
                      </div>
                    );
                  }}
                </Form.List>

                <div className="pms-summary__form-guest">
                  <div className="flex items-center justify-between">
                    <div className="number-guest-cell">
                      <div className="number-guest-cell__type">
                        <UserOutlined style={{ fontSize: '20px', color: '#00000040' }} />
                        <span>{totalAdult}</span>
                      </div>
                      <div className="number-guest-cell__type">
                        <IconBaby style={{ width: '20px', height: '20px' }} />
                        <span>{totalChild}</span>
                      </div>
                    </div>
                    <p className="text-secondary m-0">{totalRoom} Phòng</p>
                  </div>
                </div>

                <div className="pms-summary__form-total">
                  <div className="flex items-center justify-between">
                    <p className="m-0">{t('common.total')}</p>
                    <p className="m-0">{totalAmount?.toLocaleString('vn')}</p>
                  </div>
                </div>

                {/*<div className="pms-summary__form-payment">*/}
                {/*  <p className="m-0 pms-summary__form-payment__title">*/}
                {/*    Thanh toán*/}
                {/*  </p>*/}
                {/*  <Space className="w-full">*/}
                {/*    <Form.Item name="currency">*/}
                {/*      <Select options={CURRENCY_OPTIONS} />*/}
                {/*    </Form.Item>*/}
                {/*    <Form.Item name="paymentMethod">*/}
                {/*      <Select options={PAYMENT_METHOD_OPTIONS} />*/}
                {/*    </Form.Item>*/}
                {/*    <Form.Item name="money">*/}
                {/*      <InputNumber*/}
                {/*        min={0}*/}
                {/*        max={totalMoney}*/}
                {/*        className="w-full"*/}
                {/*      />*/}
                {/*    </Form.Item>*/}
                {/*  </Space>*/}

                {/*  <div className="flex items-center justify-between">*/}
                {/*    <p className="m-0">Còn lại</p>*/}
                {/*    <p className="m-0 text-secondary">*/}
                {/*      {remainMoney?.toLocaleString("vn")}*/}
                {/*    </p>*/}
                {/*  </div>*/}
                {/*</div>*/}
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default BookingRoom;
