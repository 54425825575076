import { useMemo, useState } from 'react';
import { nanoid } from 'nanoid';

import { Button, Card, Form, FormProps, Table, DatePicker } from 'antd';
import dayjs from 'dayjs';

import { useGetBreakfasts } from 'hooks/useGetBreakfasts';
import { getColumnBreakfast } from 'constants/table';
import ExportBreakfastList from 'components/common/export-breakfast-list';
import { getEndOfToday, getStartOfToday, toLocalTime } from 'utils';

import 'styles/breakfasts.scss';
import { useTranslation } from 'react-i18next';

function Breakfasts() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [date, setDate] = useState<string | undefined>(dayjs().format('YYYY-MM-DD HH:mm:ss'));
  const { data, isFetching } = useGetBreakfasts(
    date ? dayjs(date).format('YYYY-MM-DD HH:mm:ss') : undefined
  );

  const dataSource = useMemo(() => {
    if (data) {
      const mappedData = data.flatMap(item =>
        item.lines.map((line, index) => ({
          booking_id: index === 0 ? item.booking_id : '',
          guest: line.guest || '',
          room_name: index === 0 ? item.room_name : '',
          arrival: line.arrival ? toLocalTime(line.arrival, 'DD/MM/YYYY - HH:mm:ss') : '',
          departure: line.departure ? toLocalTime(line.departure, 'DD/MM/YYYY - HH:mm:ss') : '',
          adult: index === 0 ? item.adult : '',
          child: index === 0 ? item.child : '',
          rowSpan: index === 0 ? item.lines.length : 0
        }))
      );

      const totalAdults = mappedData.reduce((sum, item) => sum + (Number(item.adult) || 0), 0);
      const totalChildren = mappedData.reduce((sum, item) => sum + (Number(item.child) || 0), 0);

      if (totalAdults > 0 || totalChildren > 0) {
        const totalRow = {
          booking_id: 'TOTAL',
          guest: '',
          room_name: '',
          adult: totalAdults || 0,
          child: totalChildren || 0,
          arrival: '',
          departure: '',
          isTotalRow: true
        };

        return [...mappedData, totalRow];
      }
      return mappedData;
    }
    return [];
  }, [data]);

  const onFinish: FormProps['onFinish'] = async (objValue: any) => {
    const truthyValues: any = Object.keys(objValue)
      .filter(key => Boolean(objValue[key]))
      .reduce((cur, next) => {
        return {
          ...cur,
          [next]: objValue[next]
        };
      }, {});
    setDate(truthyValues.date || undefined);
  };

  const columns = getColumnBreakfast
    ? getColumnBreakfast(t).map(column => {
        const originalRender = column.render;

        return {
          ...column,
          render: (text: any, record: any, index: any) => {
            if (record.isTotalRow) {
              return <strong>{text}</strong>;
            }

            if (originalRender) {
              return originalRender(text, record, index);
            }
            return text;
          }
        };
      })
    : [];

  return (
    <div className="pms-breakfast-list">
      <Card title={t('managePage.breakfastPage.title')} style={{ width: '100%' }}>
        <Form
          form={form}
          name="breakfast-filter-form"
          layout="inline"
          initialValues={{
            date: dayjs()
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <div className="pms-breakfast-list__filter-wrapper">
            <div className="pms-breakfast-list__filter">
              <Form.Item name="date" className="m-0">
                <DatePicker format={'DD-MM-YYYY'} placeholder={t('common.time.selectDate')} />
              </Form.Item>

              <Button htmlType="submit" className="ant-btn-secondary btn-submit">
                {t('common.actions.submitButton')}
              </Button>
            </div>
            <ExportBreakfastList
              fileName={`breakfast-list-${getStartOfToday()}-${getEndOfToday()}-${nanoid()}`}
              dataToExport={dataSource}
              t={t}
            />
          </div>
        </Form>

        <div className="pms-room-lock__table">
          <Table
            loading={isFetching}
            rowKey="id"
            columns={columns}
            dataSource={dataSource}
            bordered={true}
            pagination={false}
            locale={{
              emptyText: <span className="empty-data">{t('common.actions.noData')}</span>
            }}
            scroll={{ x: 500, y: 'calc(100vh - 320px)' }}
          />
        </div>
      </Card>
    </div>
  );
}

export default Breakfasts;
