import { axiosInstance } from '../base.api';
import { paths } from '../paths';
import {
  BookingLine,
  ParamsCancelBooking,
  BookingServiceType,
  ParamsChangePrices,
  CreateBookingResponseType,
  ParamsExtraInfo,
  UpdateInvoiceDetailsType,
  ResponseTimeline,
  ParamsBatchAction,
  BookingsBatchActions,
  TransactionCodesResponse,
  AddonServiceTaxes,
  ParamsAddDiscount,
  ParamsAddDiscountImage,
  BookingDiscount,
  CreateDummyBooking,
  BookingConfirmationTemplate
} from '../type/booking.type';
import { RoomType } from 'services/api/type/room.type';

export const PAGE_SIZE_BOOKING_LIST = 15;
export const getBookingList = (
  filterOptions: any,
  page?: number,
  pageSize?: number
): Promise<BookingLine[]> => {
  return axiosInstance.get(paths.bookings(page, pageSize), {
    params: filterOptions
  });
};
export const getAllBookings = (filterOptions: any): Promise<BookingLine[]> => {
  return axiosInstance.get(paths.allBookings(), {
    params: filterOptions
  });
};
export const getPendingPostRoomCharge = (filterOptions: any): Promise<BookingLine[]> => {
  return axiosInstance.get(paths.getPendingPostRoomCharge(), {
    params: filterOptions
  });
};
export const getAddonServiceTaxes = async (): Promise<AddonServiceTaxes[]> => {
  return axiosInstance.get(paths.getAddonServiceTaxes()).then(item => item.data.data);
};

export const getBookingDetail = async (id: number): Promise<BookingLine> => {
  return axiosInstance.get(paths.bookingDetail(id)).then(item => item.data.data);
};

export const updateInvoiceDetails = async (
  id: number,
  params: Partial<UpdateInvoiceDetailsType>
): Promise<void> => {
  return axiosInstance.put(paths.updateInvoiceDetails(id), params).then(item => item.data.data);
};

export const getAvailRoomType = async (startDate: string, endDate: string): Promise<RoomType[]> => {
  return axiosInstance.get(paths.getAvailRoomType(startDate, endDate)).then(item => item.data.data);
};

export const actionCheckIn = async (id: number): Promise<BookingLine> => {
  return axiosInstance.post(paths.actionCheckIn(id)).then(item => item.data.data);
};

export const actionDuplicateBookingLine = async (id: number): Promise<any> => {
  return axiosInstance.post(paths.actionDuplicateBookingLine(id)).then(item => item.data.data);
};

export const bookingsBatchActions = async (params: BookingsBatchActions): Promise<any> => {
  return axiosInstance.put(paths.bookingsBatchActions(), params).then(item => item.data.data);
};

export const actionCheckOut = async (id: number): Promise<BookingLine> => {
  return axiosInstance.post(paths.actionCheckOut(id)).then(item => item.data.data);
};

export const actionChangeDate = async (
  id: number,
  check_in: string,
  check_out: string
): Promise<BookingLine> => {
  return axiosInstance
    .post(paths.actionChangeDate(id), {
      check_in,
      check_out
    })
    .then(item => item.data.data);
};

export const actionCreateBooking = async (data: any): Promise<CreateBookingResponseType> => {
  return axiosInstance.post(paths.bookings(), data).then(item => item.data.data);
};

export const assignCustomer = async (data: any): Promise<BookingLine> => {
  return axiosInstance.post(paths.assignCustomer(), data).then(item => item.data.data);
};

export const actionCancelBooking = async (
  bookingId: number,
  payload: ParamsCancelBooking
): Promise<BookingLine> => {
  return axiosInstance.post(paths.cancelBooking(bookingId), payload).then(item => item.data.data);
};

export const actionUndoCancelledBooking = async (bookingLineId: number): Promise<any> => {
  return axiosInstance.put(paths.undoCancelledBooking(bookingLineId)).then(item => item.data.data);
};

export const sendEmail = async (bookingId: number): Promise<BookingLine> => {
  return axiosInstance.post(paths.sendEmail(bookingId)).then(item => item.data.data);
};

export const getServices = async (): Promise<BookingServiceType[]> => {
  return axiosInstance.get(paths.getServices()).then(item => item.data.data);
};

export const getBookingConfirmationTemplate = async (
  bookingId?: number
): Promise<BookingConfirmationTemplate[]> => {
  return axiosInstance
    .get(paths.getBookingConfirmationTemplate(bookingId))
    .then(item => item.data.data);
};

export const sendBookingConfirmation = async (body?: BookingConfirmationTemplate): Promise<any> => {
  return axiosInstance
    .post(paths.sendBookingConfirmation(), {
      ...body
    })
    .then(item => item.data.data);
};

export const addServices = async (
  bookingLineId: number,
  params: {
    services: BookingServiceType[];
  }
): Promise<void> => {
  return axiosInstance.post(paths.addServices(bookingLineId), params).then(item => item.data.data);
};

export const changePrices = async (
  bookingLineId: number,
  params: ParamsChangePrices
): Promise<void> => {
  return axiosInstance.post(paths.changePrices(bookingLineId), params).then(item => item.data.data);
};

export const updateExtraInfo = async (
  bookingLineId: number,
  params: ParamsExtraInfo
): Promise<void> => {
  return axiosInstance
    .post(paths.updateExtraInfo(bookingLineId), params)
    .then(item => item.data.data);
};

export const removeService = async (
  bookingLineId: number,
  params: {
    service_id: number;
  }
): Promise<void> => {
  return axiosInstance
    .post(paths.removeService(bookingLineId), params)
    .then(item => item.data.data);
};

export const cancelPayment = async (payment_id: number, cancel_reason: string): Promise<void> => {
  return axiosInstance
    .post(paths.cancelPayment(), { payment_id, cancel_reason })
    .then(item => item.data.data);
};

export const cancelExpenseCashTransactionV2 = async (
  transaction_id: number,
  cancel_reason: string
): Promise<void> => {
  return axiosInstance
    .put(paths.cancelExpenseCashTransactionV2(), { transaction_id, cancel_reason })
    .then(item => item.data.data);
};

export const updateBreakfast = async (
  bookingLineId: number,
  breakfast_dates: { date: string }[]
): Promise<void> => {
  return axiosInstance
    .post(paths.updateBreakfast(bookingLineId), { breakfast_dates })
    .then(item => item.data.data);
};

export const undoCheckIn = async (bookingLineId: number, reason: string): Promise<void> => {
  return axiosInstance
    .post(paths.undoCheckIn(bookingLineId), { reason })
    .then(item => item.data.data);
};

export const undoCheckOut = async (bookingLineId: number, reason: string): Promise<void> => {
  return axiosInstance
    .post(paths.undoCheckOut(bookingLineId), { reason })
    .then(item => item.data.data);
};

export const getAllBookingLinesByBookingId = (bookingId?: number): Promise<BookingLine[]> => {
  return axiosInstance.get(paths.getAllBookingLines(bookingId)).then(item => item.data.data);
};

export const uploadConfirmation = (bookingLineId: number, params: any): Promise<void> => {
  return axiosInstance
    .post(paths.uploadConfirmation(bookingLineId), params)
    .then(item => item.data.data);
};

export const getBookingTimeline = async (
  start_date: string,
  end_date: string
): Promise<ResponseTimeline> => {
  return axiosInstance
    .get(paths.getTimeline(), {
      params: { start_date, end_date }
    })
    .then(item => item.data.data);
};

export const postRoomCharge = async ({
  id,
  to_date,
  from_date,
  folio_balance_code,
  post_option,
  amount
}: {
  id: number;
  from_date: string;
  to_date: string;
  folio_balance_code: string;
  post_option?: string;
  amount?: number;
}): Promise<ResponseTimeline> => {
  return axiosInstance
    .post(paths.postRoomCharge(id), { from_date, to_date, folio_balance_code, post_option, amount })
    .then(item => item.data.data);
};

export const moveTransaction = async ({
  guest_id,
  transaction_ids,
  folio_balance_code
}: {
  guest_id: number;
  transaction_ids: number[];
  folio_balance_code: string;
}): Promise<void> => {
  return axiosInstance
    .post(paths.moveTransaction(guest_id), { transaction_ids, folio_balance_code })
    .then(item => item.data.data);
};

export const moveSaleOrder = async ({
  guest_id,
  guest_order_id
}: {
  guest_id: number;
  guest_order_id: number;
}): Promise<void> => {
  return axiosInstance
    .post(paths.moveSaleOrder(guest_id), { guest_order_id })
    .then(item => item.data.data);
};

export const cancelTransactions = async ({
  transaction_ids,
  note
}: {
  transaction_ids: number[];
  note: string;
}): Promise<void> => {
  return axiosInstance
    .post(paths.cancelTransaction(), { transaction_ids, note })
    .then(item => item.data.data);
};

export const undoCancelledTransactions = async ({
  cancelled_transaction_ids
}: {
  cancelled_transaction_ids: number[];
}): Promise<void> => {
  return axiosInstance
    .put(paths.undoCancelledTransactions(), { cancelled_transaction_ids })
    .then(item => item.data.data);
};

export const batchActionBookings = async (data: ParamsBatchAction): Promise<any> => {
  return axiosInstance.post(paths.batchAction(), data).then(item => item.data.data);
};

export const postPayment = async ({
  guest_id,
  folio_balance_code,
  note,
  transaction_code_id,
  amount,
  transaction_date
}: {
  guest_id: number;
  folio_balance_code: string;
  note: string;
  transaction_code_id: number;
  amount: number;
  transaction_date: string;
}): Promise<void> => {
  return axiosInstance
    .post(paths.postPayment(guest_id), {
      folio_balance_code,
      note,
      transaction_code_id,
      amount,
      transaction_date
    })
    .then(item => item.data.data);
};

export const postRefund = async ({
  guest_id,
  folio_balance_code,
  note,
  transaction_code_id,
  amount,
  transaction_date
}: {
  guest_id: number;
  folio_balance_code: string;
  note: string;
  transaction_code_id: number;
  amount: number;
  transaction_date: string;
}): Promise<void> => {
  return axiosInstance
    .post(paths.postRefund(guest_id), {
      folio_balance_code,
      note,
      transaction_code_id,
      amount,
      transaction_date
    })
    .then(item => item.data.data);
};

export const transactionCode = async ({
  payment_type
}: {
  payment_type?: string;
}): Promise<TransactionCodesResponse[]> => {
  return axiosInstance
    .get(paths.transactionCodes(), {
      params: { payment_type }
    })
    .then(item => item.data.data);
};

export const editTransactionNote = async ({
  transaction_id,
  note
}: {
  transaction_id: number;
  note: string;
}): Promise<void> => {
  return axiosInstance.put(paths.editTransactionNote(), { transaction_id, note });
};

export const buildTransactionsInvoice = async ({
  guest_order_id,
  publish_einvoice,
  name,
  vat,
  email,
  company_name,
  address
}: {
  guest_order_id: number;
  publish_einvoice: boolean;
  name: string;
  vat: string;
  email?: string;
  company_name?: string;
  address?: string;
}): Promise<void> => {
  return axiosInstance
    .post(paths.buildTransactionsInvoice(), {
      guest_order_id,
      publish_einvoice,
      minvoice_customer: {
        name: name,
        vat: vat,
        email: email ? email : undefined,
        company_name: company_name,
        address: address
      }
    })
    .then(item => item.data.data);
};

export const addDiscount = async (
  bookingLineId: number,
  params: ParamsAddDiscount
): Promise<any> => {
  return axiosInstance.post(paths.addDiscount(bookingLineId), params).then(item => item.data.data);
};

export const addDiscountImage = async (
  booking_line_id: number,
  params: ParamsAddDiscountImage
): Promise<any> => {
  return axiosInstance
    .post(paths.addDiscountImage(booking_line_id), params)
    .then(item => item.data.data);
};

export const getBookingDiscount = async (booking_line_id: number): Promise<BookingDiscount> => {
  return axiosInstance.get(paths.getBookingDiscount(booking_line_id)).then(item => item.data.data);
};

export const createDummyBooking = async ({
  group_id,
  check_in,
  check_out
}: {
  group_id: number;
  check_in: string;
  check_out: string;
}): Promise<CreateDummyBooking> => {
  const response = await axiosInstance.post(paths.createDummyBooking(group_id), {
    check_in: check_in,
    check_out: check_out
  });
  return response.data.data;
};

export const createSurvey = async ({
  booking_line_id,
  survey_type,
  lang
}: {
  booking_line_id: number;
  survey_type: string;
  lang: string;
}): Promise<any> => {
  const response = await axiosInstance.post(paths.createSurvey(booking_line_id, survey_type, lang));
  return response.data.data;
};
