import { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  FormProps,
  Input,
  Modal,
  notification,
  Row
} from 'antd';
import useModal from 'stores/useModal';

import { useMutation } from '@tanstack/react-query';
import { buildTransactionsInvoice, updateInvoiceDetails } from 'services/api/module/booking.api';
import 'styles/post-payment-transaction.scss';
import { toLocalTime } from 'utils';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
import { EmailRegex } from 'constants/regex';
import { MESSAGE_CODE } from 'constants/validate';
import { useGetBookingDetail } from 'hooks/useGetBookingDetail';
import { useGetGuestOrderDetail } from 'hooks/useGetGuestTransactions';
import 'styles/create-invoice-modal.scss';
import { useGetTravelAgency } from 'hooks/useGetTravelAgencies';
import { useTranslation } from 'react-i18next';

function CreateInvoice() {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { createInvoiceTransaction, closeCreateInvoiceTransaction, setIsOpenViewPrintInvoiceV2 } =
    useModal();

  const [publishEinvoice, setPublishEinvoice] = useState(false);

  const { data: bookingLineDetail } = useGetBookingDetail(createInvoiceTransaction.booking_line_id);
  const { data: travelAgencyData } = useGetTravelAgency(bookingLineDetail?.hotel_travel_agency_id);

  const currentOrder = useMemo(() => {
    return createInvoiceTransaction.orders
      ?.filter(item => item.folio_balance_code === createInvoiceTransaction.code)
      ?.at(0);
  }, [createInvoiceTransaction]);

  const currentCustomerBookingData = useMemo(() => {
    return bookingLineDetail?.booking_line_guests
      .filter(item => item.id === currentOrder?.booking_line_guest_id)
      .at(0);
  }, [bookingLineDetail?.booking_line_guests, currentOrder?.booking_line_guest_id]);

  const { data: orderDetail } = useGetGuestOrderDetail(currentOrder ? currentOrder.id : 0);

  const { mutateAsync: mutateBuildTransactionsInvoice } = useMutation({
    mutationFn: (params: any) =>
      buildTransactionsInvoice({
        guest_order_id: params.guest_order_id,
        publish_einvoice: params.publish_einvoice,
        name: params.name,
        vat: params.vat,
        email: params.email,
        company_name: params.company_name,
        address: params.address
      })
  });

  const { mutateAsync: UpdateInvoiceDetails } = useMutation({
    mutationFn: async (values: any) => {
      const id = bookingLineDetail?.booking_line_id;
      if (id === undefined) {
        return;
      }
      const data = {
        partner_name: values.partner_name || '',
        company_name: values.company || '',
        company_address: values.company_address || '',
        tax_code: values.tax_code || '',
        email: values.email || '',
        publish_einvoice: values.publish_einvoice || false
      };

      const bookingDetail = await updateInvoiceDetails(id, data);
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_BOOKING_DETAIL, id]
      });
      return bookingDetail;
    }
  });

  const handleCloseModal = () => {
    form.resetFields();
    closeCreateInvoiceTransaction();
  };

  const vatRules = publishEinvoice
    ? [{ required: true, message: MESSAGE_CODE.REQUIRED_VAT_CODE }]
    : [];

  const handleShowInvoice = async () => {
    setIsOpenViewPrintInvoiceV2(true);
  };

  const handleCreateInvoice = async () => {
    const { name, company_name, address, vat, email, publish_einvoice } = form.getFieldsValue();
    await UpdateInvoiceDetails({
      partner_name: name,
      company: company_name,
      company_address: address,
      tax_code: vat,
      email: email,
      publish_einvoice: publish_einvoice
    });
    form.submit();
  };

  const handleSaveInfo = async () => {
    const { name, company_name, address, vat, email, publish_einvoice } = form.getFieldsValue();
    try {
      await UpdateInvoiceDetails({
        partner_name: name,
        company: company_name,
        company_address: address,
        tax_code: vat,
        email: email,
        publish_einvoice: publish_einvoice
      });
      notification.success({
        message: t('common.message.saveInvoiceInfo')
      });
    } catch (err: any) {
      notification.error({
        message: err.error || t('common.error')
      });
    }
  };

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    if (
      createInvoiceTransaction.guest_id &&
      createInvoiceTransaction.orders &&
      createInvoiceTransaction.code
    ) {
      try {
        await mutateBuildTransactionsInvoice({
          guest_order_id: currentOrder.id,
          publish_einvoice: values.publish_einvoice,
          name: values.name,
          vat: values.vat,
          email: values.email,
          company_name: values.company_name,
          address: values.address
        });
        notification.success({
          message: t('common.message.createInvoice')
        });
        closeCreateInvoiceTransaction();
        form.resetFields();
      } catch (error: any) {
        notification.error({
          message: error.error || t('common.error')
        });
      } finally {
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS, createInvoiceTransaction.guest_id]
        });
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_GUEST]
        });
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS, QUERY_KEYS.GET_GUEST_TRANSACTIONS_DETAIL]
        });
        await queryClient.invalidateQueries({
          queryKey: [
            QUERY_KEYS.KEY_ROOM,
            QUERY_KEYS.GET_BOOKING_DETAIL,
            bookingLineDetail?.booking_line_id
          ]
        });
      }
    }
  };

  useEffect(() => {
    if (createInvoiceTransaction.isOpenCreateInvoice) {
      const publish_einvoice = bookingLineDetail?.invoice_details.publish_einvoice || false;
      setPublishEinvoice(publish_einvoice);
      if (travelAgencyData || bookingLineDetail?.invoice_details.company_name) {
        const emailValue =
          bookingLineDetail?.invoice_details?.email || travelAgencyData?.email || '';

        form.setFieldsValue({
          name: '',
          email: emailValue,
          address: bookingLineDetail?.invoice_details.company_address || travelAgencyData?.address,
          company_name:
            bookingLineDetail?.invoice_details.company_name || travelAgencyData?.invoice_name,
          vat: bookingLineDetail?.invoice_details.tax_code || travelAgencyData?.vat,
          publish_einvoice: publish_einvoice
        });
      } else {
        const emailValue =
          bookingLineDetail?.invoice_details?.email || currentCustomerBookingData?.email || '';

        form.setFieldsValue({
          name: bookingLineDetail?.invoice_details.partner_name || orderDetail?.guest_name,
          email: emailValue,
          address:
            bookingLineDetail?.invoice_details.company_address ||
            currentCustomerBookingData?.address,
          vat: bookingLineDetail?.invoice_details.tax_code || '',
          publish_einvoice: publish_einvoice
        });
      }
    } else {
      form.resetFields();
      setPublishEinvoice(false);
    }
  }, [
    orderDetail,
    form,
    currentCustomerBookingData,
    travelAgencyData,
    bookingLineDetail,
    createInvoiceTransaction.isOpenCreateInvoice
  ]);

  return (
    <Modal
      title={t('cashierPage.invoice.title')}
      className="modal-create-invoice"
      centered
      width={750}
      open={createInvoiceTransaction.isOpenCreateInvoice}
      cancelText={t('common.actions.cancel')}
      okButtonProps={{ className: 'btn-secondary' }}
      onOk={handleCreateInvoice}
      onCancel={handleCloseModal}
      destroyOnClose
      footer={[
        <Button
          key="save_info"
          type="primary"
          className="btn-print-invoice"
          onClick={handleSaveInfo}
        >
          {t('cashierPage.invoice.saveInfo')}
        </Button>,
        <Button
          key="print_invoice"
          type="primary"
          className="btn-print-invoice"
          onClick={handleShowInvoice}
        >
          {t('cashierPage.invoice.printInvoice')}
        </Button>,
        <Button
          key="ok"
          type="primary"
          className="btn-create-invoice"
          onClick={handleCreateInvoice}
          disabled={currentOrder?.state === 'invoiced'}
        >
          {t('cashierPage.invoice.createInvoice')}
        </Button>,
        <Button key="cancel" className="btn-cancel" onClick={handleCloseModal}>
          {t('cashierPage.invoice.cancel')}
        </Button>
      ]}
    >
      <Card title="RESERVATION INFORMATION" className="reservation-info">
        <Row gutter={[16, 0]}>
          <Col span={4} className="col-bold">
            <p>{t('cashierPage.invoice.resv')}</p>
          </Col>
          <Col span={4}>
            <p>{bookingLineDetail?.booking_line_id}</p>
          </Col>
          <Col span={4} className="col-bold">
            <p>{t('cashierPage.invoice.confirm')}</p>
          </Col>
          <Col span={4}>
            <p>{bookingLineDetail?.booking_id}</p>
          </Col>
          <Col span={4} className="col-bold">
            <p>{t('cashierPage.common.room')}</p>
          </Col>
          <Col span={4}>
            <p>
              {bookingLineDetail?.room_name
                ? bookingLineDetail.room_name
                : bookingLineDetail?.room_type_name}
            </p>
          </Col>
          <Col span={4} className="col-bold">
            <p>{t('cashierPage.invoice.arrivalDate')}</p>
          </Col>
          <Col span={4}>
            <p>
              {bookingLineDetail?.check_in
                ? toLocalTime(bookingLineDetail.check_in, 'DD/MM/YYYY')
                : ''}
            </p>
          </Col>
          <Col span={4} className="col-bold">
            <p>{t('cashierPage.invoice.departureDate')}</p>
          </Col>
          <Col span={4}>
            <p>
              {bookingLineDetail?.check_out
                ? toLocalTime(bookingLineDetail.check_out, 'DD/MM/YYYY')
                : ''}
            </p>
          </Col>
          {travelAgencyData && (
            <>
              <Col span={4} className="col-bold">
                <p>{t('common.customer.group')}</p>
              </Col>
              <Col span={4}>
                <p>{travelAgencyData.name}</p>
              </Col>
            </>
          )}
        </Row>
      </Card>

      <Form
        form={form}
        name="create-invoice-form"
        layout="vertical"
        style={{ width: '100%' }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Card title={t('cashierPage.invoice.guestInfo')} className="guest-info">
          <Row gutter={[16, 0]}>
            <Col span={6} className="label">
              <p>{t('cashierPage.invoice.exportVatEInvoice')}</p>
            </Col>
            <Col span={18}>
              <Form.Item name="publish_einvoice" valuePropName="checked">
                <Checkbox onChange={e => setPublishEinvoice(e.target.checked)} />
              </Form.Item>
            </Col>
            <Col span={4} className="label">
              <p>{t('cashierPage.common.guestName')}</p>
            </Col>
            <Col span={20}>
              <Form.Item name="name">
                <Input />
              </Form.Item>
            </Col>
            <Col span={4} className="label">
              <p>{t('common.customer.email')}</p>
            </Col>
            <Col span={20}>
              <Form.Item
                name="email"
                rules={[
                  {
                    pattern: EmailRegex,
                    message: MESSAGE_CODE.WRONG_FORMAT_EMAIL
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={4} className="label">
              <p>{t('common.customer.company')}</p>
            </Col>
            <Col span={20}>
              <Form.Item name="company_name">
                <Input />
              </Form.Item>
            </Col>
            <Col span={4} className="label">
              <p>{t('common.customer.address')}</p>
            </Col>
            <Col span={20}>
              <Form.Item name="address">
                <Input />
              </Form.Item>
            </Col>
            {publishEinvoice && (
              <>
                <Col span={4} className="label">
                  <p>
                    {t('common.customer.taxCode')} <span className="required">*</span>
                  </p>
                </Col>
                <Col span={20}>
                  <Form.Item name="vat" rules={vatRules}>
                    <Input />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </Card>
      </Form>
    </Modal>
  );
}

export default CreateInvoice;
