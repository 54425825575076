import { BookingStatus } from 'services/api/type/booking.type';
import { CustomMenuItem, PermissionActionTypes } from 'helper/type/action.type';

export enum TIMELINE_UNIT {
  SECOND = 'second',
  MINUTE = 'minute',
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  ISO_WEEK = 'isoWeek',
  MONTH = 'month',
  YEAR = 'year'
}

export enum LANGUAGE {
  EN = 'en',
  VI = 'vi'
}

export const LANGUAGE_OPTIONS = [
  { label: 'VI', value: LANGUAGE.VI },
  { label: 'EN', value: LANGUAGE.EN }
];

export enum FILTER_STATUS_ROOM_MAP_VALUE {
  ALL = 'all',
  AVAILABLE = 'available',
  BOOKED = 'booked',
  NO_SHOW = 'no_show',
  CHECKIN = 'checkin',
  NOT_CHECKOUT = 'overdue',
  DIRTY = 'dirty',
  LOCKED = 'locked'
}

export const MAP_FILTER_STATUS_LABEL: { [key: string]: string } = {
  [FILTER_STATUS_ROOM_MAP_VALUE.AVAILABLE]: 'common.statuses.available',
  [FILTER_STATUS_ROOM_MAP_VALUE.BOOKED]: 'common.statuses.booked',
  [FILTER_STATUS_ROOM_MAP_VALUE.NO_SHOW]: 'common.statuses.notCheckedIn',
  [FILTER_STATUS_ROOM_MAP_VALUE.CHECKIN]: 'common.statuses.haveGuests',
  [FILTER_STATUS_ROOM_MAP_VALUE.NOT_CHECKOUT]: 'common.statuses.notCheckedOut',
  [FILTER_STATUS_ROOM_MAP_VALUE.LOCKED]: 'roomMap.lockedRoom'
};

export const FILTER_STATUS_ROOM_MAP = [
  {
    label: 'common.statuses.all',
    value: FILTER_STATUS_ROOM_MAP_VALUE.ALL,
    color: '#EFAC00',
    bgColor: '#FFF2CF'
  },
  {
    label: 'common.statuses.available',
    value: FILTER_STATUS_ROOM_MAP_VALUE.AVAILABLE,
    color: '#42A046',
    bgColor: '#E4FBE5'
  },
  {
    label: 'common.statuses.booked',
    value: FILTER_STATUS_ROOM_MAP_VALUE.BOOKED,
    color: '#0782EB',
    bgColor: '#EBF6FF'
  },
  {
    label: 'common.statuses.notCheckedIn',
    value: FILTER_STATUS_ROOM_MAP_VALUE.NO_SHOW,
    color: '#9513A8',
    bgColor: '#FDECFF'
  },
  {
    label: 'common.statuses.haveGuests',
    value: FILTER_STATUS_ROOM_MAP_VALUE.CHECKIN,
    color: '#CE370F',
    bgColor: '#FFE7E0'
  },
  {
    label: 'common.statuses.notCheckedOut',
    value: FILTER_STATUS_ROOM_MAP_VALUE.NOT_CHECKOUT,
    color: '#E8781B',
    bgColor: '#FFECDD'
  },
  {
    label: 'common.statuses.dirty',
    value: FILTER_STATUS_ROOM_MAP_VALUE.DIRTY,
    color: '#100F0F',
    bgColor: '#E2E0E0'
  }
];

export const LEGEND_STATUS_TIMELINE = [
  {
    label: 'common.statuses.booked',
    value: BookingStatus.CONFIRM,
    color: '#0782EB',
    bgColor: '#EBF6FF'
  },
  {
    label: 'common.statuses.notCheckedIn',
    value: BookingStatus.CONFIRM,
    color: '#9513A8',
    bgColor: '#FDECFF'
  },
  {
    label: 'common.statuses.haveGuests',
    value: BookingStatus.CHECK_IN,
    color: '#CE370F',
    bgColor: '#FFE7E0'
  },
  {
    label: 'common.statuses.notCheckedOut',
    value: BookingStatus.CHECK_IN,
    color: '#E8781B',
    bgColor: '#FFECDD'
  },
  {
    label: 'common.statuses.wasCheckedOut',
    value: BookingStatus.CHECK_OUT,
    color: '#EE82EE',
    bgColor: '#FFECDD'
  },
  {
    label: 'common.statuses.dirty',
    value: FILTER_STATUS_ROOM_MAP_VALUE.DIRTY,
    color: '#100F0F',
    bgColor: '#E2E0E0'
  },
  {
    label: 'bookingPage.roomMap.lockedRoom',
    value: FILTER_STATUS_ROOM_MAP_VALUE.LOCKED,
    color: '#6C7175',
    bgColor: '#E2E0E0'
  }
];

export const FILTER_STATUS_ROOM_MAP_BACKGROUND: {
  [key: string]: string;
} = {
  [BookingStatus.CONFIRM]: 'rgb(30, 136, 229)',
  [BookingStatus.CHECK_IN]: 'rgb(217, 57, 13)',
  [BookingStatus.CHECK_OUT]: 'rgb(238, 130, 238)',
  [FILTER_STATUS_ROOM_MAP_VALUE.LOCKED]: 'rgb(108, 113, 117)'
};

export enum FILTER_DISPLAY_ROOM_MAP_VALUE {
  BOOKING = 'booking',
  CATEGORIES = 'categories',
  FLOOR = 'floor',
  ROOM = 'room'
}

export const FILTER_DISPLAY_ROOM_MAP = [
  {
    label: 'common.bookingData.booking',
    value: FILTER_DISPLAY_ROOM_MAP_VALUE.BOOKING
  },
  {
    label: 'bookingPage.roomMap.type',
    value: FILTER_DISPLAY_ROOM_MAP_VALUE.CATEGORIES
  },
  {
    label: 'bookingPage.roomMap.floor',
    value: FILTER_DISPLAY_ROOM_MAP_VALUE.FLOOR
  },
  {
    label: 'common.bookingData.room',
    value: FILTER_DISPLAY_ROOM_MAP_VALUE.ROOM
  }
];

export enum DISPLAY_ROOM_VALUE {
  DETAIL = 'detail',
  SIMPLE = 'simple',
  TIMELINE = 'timeline'
}

export const MENU_ASSIGN_ROOM_VALUE = {
  CHANGE_ROOM_TYPE: 'CHANGE_ROOM_TYPE',
  ASSIGN_ROOM: 'ASSIGN_ROOM',
  CANCEL_ASSIGN_ROOM: 'CANCEL_ASSIGN_ROOM',
  CANCEL_ROOM: 'CANCEL_ROOM',
  UNDO_CHECK_IN: 'UNDO_CHECK_IN',
  UNDO_CHECK_OUT: 'UNDO_CHECK_OUT',
  UNDO_CANCELLED_BOOKING: 'UNDO_CANCELLED_BOOKING'
};

export const MenuAssignRoom: CustomMenuItem[] = [
  {
    key: MENU_ASSIGN_ROOM_VALUE.CHANGE_ROOM_TYPE,
    label: 'common.actions.changeRoomType'
  },
  {
    key: MENU_ASSIGN_ROOM_VALUE.ASSIGN_ROOM,
    label: 'common.actions.assignRoom',
    permission: PermissionActionTypes.ASSIGN_ROOM
  },
  {
    key: MENU_ASSIGN_ROOM_VALUE.CANCEL_ASSIGN_ROOM,
    label: 'common.actions.unAssignRoom',
    permission: PermissionActionTypes.UNASSIGN_ROOM
  },
  {
    key: MENU_ASSIGN_ROOM_VALUE.CANCEL_ROOM,
    label: 'common.actions.cancelBookingNoShow',
    permission: PermissionActionTypes.CANCEL_BOOKING
  },
  {
    key: MENU_ASSIGN_ROOM_VALUE.UNDO_CHECK_IN,
    label: 'common.actions.undoCheckedIn'
  },
  {
    key: MENU_ASSIGN_ROOM_VALUE.UNDO_CHECK_OUT,
    label: 'common.actions.undoCheckedOut'
  },
  {
    key: MENU_ASSIGN_ROOM_VALUE.UNDO_CANCELLED_BOOKING,
    label: 'common.actions.undoCancelledBooking',
    permission: PermissionActionTypes.UNDO_CANCELLED_BOOKING
  }
];

export const FILTER_CLEANING_DETAIL = {
  ROOM: 'ROOM',
  FLOOR: 'FLOOR',
  ROOM_TYPE: 'ROOM_TYPE'
};

export const FILTER_CLEANING_DETAIL_OPTIONS = [
  {
    label: 'common.bookingData.room',
    value: FILTER_CLEANING_DETAIL.ROOM
  },
  {
    label: 'bookingPage.roomMap.floor',
    value: FILTER_CLEANING_DETAIL.FLOOR
  },
  {
    label: 'common.bookingData.roomType',
    value: FILTER_CLEANING_DETAIL.ROOM_TYPE
  }
];

export const RANGE_DATE_CLEANING_DETAIL_OPTIONS = [
  {
    label: 'roomPage.cleaningCalendar.sevenDays',
    value: 7
  },
  {
    label: 'roomPage.cleaningCalendar.fourteenDays',
    value: 14
  },
  {
    label: 'roomPage.cleaningCalendar.thirty',
    value: 30
  }
];

export const MAP_GENDER: {
  [key: string]: string;
} = {
  male: 'M',
  female: 'F'
};

export enum GENDER_NAME {
  M = 'Nam',
  F = 'Nữ'
}

export enum CASHFLOW_TYPE {
  INCOME = 'income',
  PAYMENT = 'expense'
}

export const MAP_NIGHT_AUDIT_STATUS: { [key: string]: string } = {
  [BookingStatus.CONFIRM]: 'Nhận phòng',
  [BookingStatus.CHECK_IN]: 'Đang ở',
  [BookingStatus.CHECK_OUT]: 'Đã đi'
};

export enum ACTION_EXPORT_DATA {
  EXCEL = 'EXCEL',
  XML = 'XML',
  PDF = 'PDF'
}

export enum CLEANING_STATUS {
  WAIT_FOR_APPROVAL = 'wait_for_approval',
  APPROVE = 'approve',
  NEW = 'new',
  CLEANING = 'cleaning'
}

export enum MINIBAR_STATUS {
  WAIT_FOR_APPROVAL = 'wait_for_approval',
  APPROVE = 'approve',
  NEW = 'new',
  CHECKED = 'checked'
}

export const FILTER_TIMELINE_UNIT = [
  {
    label: 'common.time.day',
    value: TIMELINE_UNIT.DAY
  },
  {
    label: 'common.time.week',
    value: TIMELINE_UNIT.WEEK
  },
  {
    label: 'common.time.month',
    value: TIMELINE_UNIT.MONTH
  }
];

export enum GROUP_DISPLAY_TYPE {
  ROOM_TYPE = 'room-type',
  ROOM = 'room'
}

export const GROUP_DISPLAY_OPTIONS = [
  {
    label: 'bookingPage.roomMap.type',
    value: GROUP_DISPLAY_TYPE.ROOM_TYPE
  },
  {
    label: 'common.bookingData.room',
    value: GROUP_DISPLAY_TYPE.ROOM
  }
];

export const ACTION_BOOKING_LINE_DETAIL_MODAL = {
  HISTORY: 'HISTORY',
  SEND_EMAIL: 'SEND_EMAIL',
  VIEW_PDF: 'VIEW_PDF',
  VIEW_CONFIRMATION: 'VIEW_CONFIRMATION',
  RELOAD_BOOKING: 'RELOAD_BOOKING'
};

export const TEMP_GUEST = 22708;

export enum LEVEL_ENUM {
  BODS = 'bods',
  KOLS = 'kols',
  COMPENSATION = 'compensation',
  PARTNER = 'partner'
}

export const LEVEL_ENUM_OPTIONS = [
  {
    label: 'BODs',
    value: LEVEL_ENUM.BODS
  },
  {
    label: 'KOLs',
    value: LEVEL_ENUM.KOLS
  },
  {
    label: 'Compensation',
    value: LEVEL_ENUM.COMPENSATION
  },
  {
    label: 'Partner',
    value: LEVEL_ENUM.PARTNER
  }
];

export const MAP_GUEST_LEVEL: {
  [key: string]: string;
} = {
  bods: 'bods',
  kols: 'kols',
  compensation: 'compensation',
  partner: 'partner'
};

export enum GUEST_LEVEL_NAME {
  bods = 'Bods',
  kols = 'Kols',
  compensation = 'Compensation',
  partner = 'Partner'
}
